import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "The best gym in town with all the facility!",
    name: 'Swet',
    status : 'Trainer'
  },
  {
    image: image2,
    review: 'Best gym with all the necessary equipments .Overall the environment is best for workout. ',
    name: 'Akshat',
    status: 'Customer'
  },
  {
    image : image3,
    review:' Best gym for beginners and 30 %off for new addmission and very best gym in our Jaunpur all equipment are available in this gym',
    name: 'Govind',
    status: "Manger"
  }
];
